import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SwarmAlive from "./SwarmAlive";
import { motion } from "framer-motion";
import { keyframes } from "styled-components";
import { ChevronDown } from "react-feather";

gsap.registerPlugin(ScrollTrigger);

const SectionContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0) 0%,
    rgba(13, 13, 13, 0.8) 100%
  );
  will-change: transform;
  transform-style: preserve-3d;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  margin: auto;

  @media (max-width: 968px) {
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  opacity: 0;
  transform: translateY(30px);
`;

const Title = styled.h2`
  font-size: clamp(2rem, 4vw, 3rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 500px;
`;

const VisualizationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 300px;
  opacity: 0;
  transform: translateX(30px);
  will-change: transform, opacity;

  @media (max-width: 968px) {
    min-height: 250px;
    width: 100%;
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    min-height: 200px;
  }
`;

const GlowEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(136, 83, 251, 0.15) 0%,
    transparent 70%
  );
  filter: blur(20px);
  pointer-events: none;
`;

const ParticleWrapper = styled.div`
  position: relative;
  width: 80vh;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  margin: 0 auto;

  @media (max-width: 968px) {
    width: 60vh;
    height: 60vh;
  }

  @media (max-width: 480px) {
    width: 40vh;
    height: 40vh;
  }
`;

const wobble = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.02) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-1deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1.02) rotate(0.5deg);
  }
`;

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(136, 83, 251, 0.7),
                0 0 30px rgba(136, 83, 251, 0.5),
                0 0 45px rgba(136, 83, 251, 0.3),
                inset 0 0 15px rgba(136, 83, 251, 0.7),
                inset 0 0 30px rgba(136, 83, 251, 0.5);
    border-color: rgba(136, 83, 251, 0.8);
  }
  50% {
    box-shadow: 0 0 25px rgba(136, 83, 251, 0.9),
                0 0 50px rgba(136, 83, 251, 0.7),
                0 0 75px rgba(136, 83, 251, 0.5),
                inset 0 0 25px rgba(136, 83, 251, 0.9),
                inset 0 0 50px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 15px rgba(136, 83, 251, 0.7), 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(136, 83, 251, 0.7),
    inset 0 0 30px rgba(136, 83, 251, 0.5);
  animation: ${pulseNeon} 2s ease-in-out infinite,
    ${wobble} 6s ease-in-out infinite;
  background: rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(5px);
  will-change: transform, box-shadow, border-color;

  @media (max-width: 968px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 480px) {
    width: 80px;
    height: 80px;
  }
`;

const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `radial-gradient(
          circle at center,
          rgba(255, 255, 255, 0.4),
          transparent 80%
        )`
      : `radial-gradient(
          circle at center,
          rgba(136, 83, 251, 0.4),
          transparent 80%
        )`};
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: highlightFloat 4s ease-in-out infinite;

  @keyframes highlightFloat {
    0%,
    100% {
      transform: translate(0, 0);
      opacity: 0.3;
    }
    50% {
      transform: translate(1px, 1px);
      opacity: 0.4;
    }
  }
`;

const ScrollArrow = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease;
  z-index: 10;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
  }

  svg {
    width: 32px;
    height: 32px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const ProblemSection = () => {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const visualRef = useRef(null);
  const scrollArrowRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const textContent = section.querySelector(TextContent);
    const visualContent = section.querySelector(VisualizationContainer);
    const scrollArrow = scrollArrowRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    tl.to(textContent, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power3.out",
    })
      .to(
        visualContent,
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .to(
        scrollArrow,
        {
          opacity: 1,
          y: [20, 0],
          duration: 1,
          ease: "power2.out",
        },
        "-=0.5"
      );

    return () => {
      const triggers = ScrollTrigger.getAll().filter(
        (trigger) => trigger.vars.trigger === section
      );
      triggers.forEach((trigger) => trigger.kill());
    };
  }, []);

  const scrollToNext = () => {
    const nextSection = sectionRef.current?.nextElementSibling;
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <SectionContainer ref={sectionRef} data-scroll-section>
      <ContentWrapper>
        <TextContent ref={textRef}>
          <Title>Overwhelmed by scattered tax deed and lien data?</Title>
          <Description>
            Stop juggling endless spreadsheets and sites. Discover a simpler
            path—one platform to explore, compare, and manage your property
            opportunities.
          </Description>
        </TextContent>
        <VisualizationContainer ref={visualRef}>
          <GlowEffect />
          <ParticleWrapper>
            <SwarmAlive
              size={
                window.innerWidth <= 480
                  ? "300px"
                  : window.innerWidth <= 968
                  ? "400px"
                  : "600px"
              }
              enableHover={true}
              active={true}
              mode="frenetic"
              instanceId="problem-section-swarm"
            />
            <BubbleContainer>
              <BubbleSkin
                animate={{
                  scale: 1.02,
                  opacity: 0.9,
                }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 20,
                }}
              />
              <Highlight />
            </BubbleContainer>
          </ParticleWrapper>
        </VisualizationContainer>
      </ContentWrapper>

      <ScrollArrow
        ref={scrollArrowRef}
        onClick={scrollToNext}
        animate={{ y: [0, 10, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <ChevronDown size={32} />
      </ScrollArrow>
    </SectionContainer>
  );
};

export default ProblemSection;
