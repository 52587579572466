import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SwarmManager from '../../services/SwarmManager';

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
  background: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.98) 100%);
    z-index: -1;
  }
`;

const ParticleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.loaded {
    opacity: 1;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

const ParticleBackground = () => {
  const containerId = useRef(`particle-bg-${Math.random().toString(36).substr(2, 9)}`);
  const containerRef = useRef(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    let instance = null;
    let cleanup = null;
    let retryCount = 0;
    const maxRetries = 5;

    const initParticles = async () => {
      try {
        if (!window.particlesJS) {
          if (retryCount < maxRetries) {
            retryCount++;
            setTimeout(initParticles, 500);
            return;
          }
          console.error('particles.js not loaded after retries');
          return;
        }

        // Wait for container to be ready
        if (!containerRef.current) {
          console.error('Container not found');
          return;
        }

        // Wait for a frame to ensure DOM is ready
        await new Promise(resolve => requestAnimationFrame(resolve));

        instance = await SwarmManager.initParticles(containerId.current, 'grid', false);
        
        if (instance) {
          setIsLoaded(true);
          cleanup = () => {
            SwarmManager.destroy(containerId.current);
          };
        }
      } catch (error) {
        console.error('Error initializing particles:', error);
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(initParticles, 500);
        }
      }
    };

    // Start initialization process with a delay
    const timer = setTimeout(initParticles, 100);

    return () => {
      clearTimeout(timer);
      if (cleanup) cleanup();
    };
  }, []);

  return (
    <BackgroundContainer ref={containerRef}>
      <ParticleContainer 
        id={containerId.current}
        className={isLoaded ? 'loaded' : ''}
      />
    </BackgroundContainer>
  );
};

export default ParticleBackground; 