// src/common/FeaturesSection.js

import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Filter, BookOpen, Star, PieChart, Zap, Users, ChevronDown } from "react-feather";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 20px rgba(136, 83, 251, 0.8),
                0 0 40px rgba(136, 83, 251, 0.6),
                0 0 60px rgba(136, 83, 251, 0.4),
                inset 0 0 20px rgba(136, 83, 251, 0.8),
                inset 0 0 40px rgba(136, 83, 251, 0.6);
    border-color: rgba(136, 83, 251, 0.9);
  }
  50% {
    box-shadow: 0 0 30px rgba(136, 83, 251, 0.9),
                0 0 60px rgba(136, 83, 251, 0.7),
                0 0 90px rgba(136, 83, 251, 0.5),
                inset 0 0 30px rgba(136, 83, 251, 0.9),
                inset 0 0 60px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;

const highlightFloat = keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(2px, -2px);
  }
`;

const SectionContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 3rem);
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    width: calc(100% - 32px);
    max-width: 100%;
  }
`;

const FeatureCard = styled.div`
  background: radial-gradient(
    circle at 50% 0%,
    rgba(136, 83, 251, 0.35),
    rgba(136, 83, 251, 0.25) 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.4);
  border-radius: 16px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: translateY(20px);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  will-change: transform, opacity;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    gap: 16px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  &:hover {
    transform: translateY(-5px);
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.45),
      rgba(136, 83, 251, 0.35) 70%
    );
    border-color: rgba(136, 83, 251, 0.5);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(136, 83, 251, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  box-shadow: 0 0 20px rgba(136, 83, 251, 0.2);
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    margin-bottom: 0;
  }

  svg {
    width: 24px;
    height: 24px;
    color: rgba(255, 255, 255, 1);
    transition: transform 0.3s ease;
    z-index: 2;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const TextContent = styled.div`
  flex: 1;
  min-width: 0; // Prevents text from overflowing

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 20px rgba(136, 83, 251, 0.8), 0 0 40px rgba(136, 83, 251, 0.6),
    inset 0 0 20px rgba(136, 83, 251, 0.8),
    inset 0 0 40px rgba(136, 83, 251, 0.6);
  background: rgba(136, 83, 251, 0.2);
  backdrop-filter: blur(5px);
  opacity: ${(props) => (props.$isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
  animation: ${pulseNeon} 2s ease-in-out infinite;
  will-change: transform, box-shadow, border-color;
`;

const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: ${highlightFloat} 4s ease-in-out infinite;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 4px;
  }
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.6;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.5;
  }
`;

const ScrollArrow = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease;
  z-index: 10;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
  }

  svg {
    width: 32px;
    height: 32px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const features = [
  {
    icon: <Filter />,
    title: "Smart Filters",
    description:
      "Quickly pinpoint deeds and liens that match your criteria with advanced filtering. No more sifting through irrelevant data—focus on what matters.",
  },
  {
    icon: <BookOpen />,
    title: "Notes & Documentation",
    description:
      "Keep detailed property records, attach important documents, and access them anytime. Your organized archive is just a click away.",
  },
  {
    icon: <Star />,
    title: "Favorites",
    description:
      "Mark properties you’re tracking as favorites for instant retrieval and comparison. Stay on top of promising leads effortlessly.",
  },
  {
    icon: <PieChart />,
    title: "Property Insights",
    description:
      "Make data-driven decisions using comprehensive analytics. Understand market trends and uncover new opportunities in the deed and lien space.",
  },
  {
    icon: <Zap />,
    title: "AI-Powered Analysis",
    description:
      "Ask questions and get clear, data-backed answers from our extensive resources. Our assistant helps you uncover essential info—no investment advice, just valuable insights.",
  },
  {
    icon: <Users />,
    title: "Collaboration Hub",
    description:
      "Share insights, compare notes, and strategize with your team in real-time. Work together seamlessly, no matter where you are.",
  },
];

const FeaturesSection = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const cardRefs = useRef([]);
  const triggersRef = useRef([]);
  const scrollArrowRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const section = sectionRef.current;
    const title = section.querySelector("h2");
    const cards = section.querySelectorAll(".feature-card");
    const scrollArrow = scrollArrowRef.current;

    gsap.set([title, ...cards], {
      opacity: 0,
      y: 20,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    tl.to(title, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power3.out",
    })
      .to(
        cards,
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .to(
        scrollArrow,
        {
          opacity: 1,
          y: [20, 0],
          duration: 1,
          ease: "power2.out",
        },
        "-=0.5"
      );

    return () => {
      const triggers = ScrollTrigger.getAll().filter(
        (trigger) => trigger.vars.trigger === section
      );
      triggers.forEach((trigger) => trigger.kill());
    };
  }, []);

  const scrollToNext = () => {
    const nextSection = sectionRef.current?.nextElementSibling;
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <SectionContainer ref={sectionRef} data-scroll-section>
      <ContentWrapper>
        <SectionTitle ref={titleRef}>
          Powerful Features at Your Fingertips
        </SectionTitle>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard
              key={feature.title}
              className="feature-card"
              ref={(el) => (cardRefs.current[index] = el)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <IconContainer>
                {feature.icon}
                <BubbleContainer $isActive={hoveredIndex === index}>
                  <BubbleSkin
                    animate={{
                      scale: 1.02,
                      opacity: 0.9,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 20,
                    }}
                  />
                  <Highlight />
                </BubbleContainer>
              </IconContainer>
              <TextContent>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </TextContent>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </ContentWrapper>

      <ScrollArrow
        ref={scrollArrowRef}
        onClick={scrollToNext}
        animate={{ y: [0, 10, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <ChevronDown size={32} />
      </ScrollArrow>
    </SectionContainer>
  );
};

export default FeaturesSection;
