import React, { useState, useContext, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Eye,
  EyeOff,
  Check,
  X,
  LogOut as ExitToAppIcon,
  Trash2,
  UserMinus,
  Globe,
  ChevronDown,
  ChevronUp,
  Settings as SettingsIcon,
  Link,
  AlertTriangle,
} from "react-feather";
import { AuthContext } from "../../context/AuthContext";
import { debounce } from "lodash";
import Toast from "../common/Toast";
import ConfirmationModal from "../common/ConfirmationModal";
import LoadingOverlay from "../common/LoadingOverlay";
import { useTranslation } from "react-i18next";
import PolicyModal from "../modals/PolicyModal";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

const PageContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  padding: 24px 0 0px;
  overflow: visible;
`;

const ValidationMessage = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -28px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.error};
  pointer-events: none;
  font-size: 13px;
  font-weight: 500;
  transform-origin: left center;
  svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }
`;

const ActionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SaveButton = styled(ActionButton)`
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid rgba(136, 83, 251, 0.3);
  color: ${({ theme }) => theme.colors.text};
  backdrop-filter: blur(20px);
  box-shadow:
    0 0 0 1px rgba(136, 83, 251, 0.2),
    0 8px 20px rgba(136, 83, 251, 0.15);
  &:hover {
    transform: translateY(-2px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow:
      0 12px 32px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
  &:active {
    transform: translateY(1px);
  }
  svg {
    filter: drop-shadow(0 0 8px rgba(136, 83, 251, 0.3));
  }
`;

const SecondaryButton = styled(motion.button)`
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(20px);
  box-shadow:
    0 0 0 1px rgba(136, 83, 251, 0.2),
    0 8px 20px rgba(136, 83, 251, 0.15);
  &:hover {
    transform: translateY(-2px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow:
      0 12px 32px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const LogoutButton = styled(ActionButton)`
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.1),
    rgba(255, 59, 48, 0.05)
  );
  border: 1px solid rgba(255, 59, 48, 0.2);
  color: rgba(255, 59, 48, 0.9);
  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.15),
      rgba(255, 59, 48, 0.1)
    );
    border-color: rgba(255, 59, 48, 0.3);
    box-shadow:
      0 8px 32px rgba(255, 59, 48, 0.15),
      0 0 0 1px rgba(255, 59, 48, 0.2);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const BottomActionsContainer = styled(motion.div)`
  width: 100%;
  max-width: 1120px;
  padding: 20px 40px;
  margin: 0 auto 10px;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(20px);
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  @media (max-width: 1200px) {
    padding: 20px;
    margin: 24px 20px;
    width: calc(100% - 40px);
  }
  @media (max-width: 768px) {
    padding: 16px;
    margin: 20px 16px;
    width: calc(100% - 32px);
    border-radius: 16px;
  }
`;

const LegalLinksContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LegalLink = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => `${theme.colors.gradient}10`};
  }
`;

const SettingsContent = styled.div`
  padding: 0 40px 40px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 0 20px 20px;
  }
  @media (max-width: 768px) {
    padding: 0 16px 16px;
  }
`;

const SettingsGrid = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: nowrap;
  margin-bottom: 24px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  .settings-section {
    flex: 1;
    min-width: 300px;
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 16px;
    padding: 24px;
    box-shadow: ${({ theme }) => theme.colors.shadow};
    @media (max-width: 1200px) {
      flex: 1 1 calc(50% - 12px);
    }
    @media (max-width: 768px) {
      flex: 1 1 100%;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.text};
    }
    input, textarea {
      background: ${({ theme }) => theme.colors.surface};
      border: 1px solid ${({ theme }) => theme.colors.border};
      color: ${({ theme }) => theme.colors.text};
      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary}20`};
      }
      &:disabled {
        background: ${({ theme }) => `${theme.colors.surface}80`};
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
    .password-requirements {
      color: ${({ theme }) => theme.colors.secondary};
      ul li {
        color: ${({ theme }) => theme.colors.secondary};
        &.met {
          color: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
`;

const AccountSettingsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: transparent;
  transition:
    color 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    background 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    background-color 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    border-color 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    box-shadow 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    pointer-events: none;
  }
`;

const AccountSettingsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  background: transparent;
  color: ${({ theme }) => theme.colors.text};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
  }
`;

const AccountSettingsTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 1.5rem;
  background: linear-gradient(45deg, #8853fb, #6039b0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: none;
  padding-bottom: 0;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  label {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    width: 100%;
    height: 48px;
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    padding: 0 16px;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    max-width: 100%;
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary}20`};
    }
    &:disabled {
      background: ${({ theme }) => `${theme.colors.surface}80`};
      color: ${({ theme }) => theme.colors.secondary};
      font-style: italic;
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
  textarea {
    height: 100px;
    resize: vertical;
    padding: 12px 16px;
    line-height: 1.5;
  }
`;

const InputLabelGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const PasswordLabelGroup = styled(InputLabelGroup)``;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  input[type="checkbox"] {
    margin-right: 10px;
    accent-color: ${({ theme }) => theme.colors.primary};
  }
  label {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};
  }
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const PasswordRequirements = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  p {
    margin: 0 0 12px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 8px;
      font-size: 14px;
      transition: color 0.3s ease;
      &.met {
        color: ${({ theme }) => theme.colors.text};
      }
      svg {
        flex-shrink: 0;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
`;

const CancelButton = styled.button`
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.1),
    rgba(255, 59, 48, 0.05)
  );
  border: 1px solid rgba(255, 59, 48, 0.2);
  color: rgba(255, 59, 48, 0.9);
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(20px);
  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.15),
      rgba(255, 59, 48, 0.1)
    );
    border-color: rgba(255, 59, 48, 0.3);
    box-shadow: 0 8px 32px rgba(255, 59, 48, 0.15),
      0 0 0 1px rgba(255, 59, 48, 0.2);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const AccountActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const LanguagePreferences = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
`;

const AdvancedActions = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
`;

const AccountActionItem = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  padding: 16px;
  border-bottom: 1px solid rgba(136, 83, 251, 0.1);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.05)
    );
  }
  &:last-child {
    border-bottom: none;
  }
`;

const LanguageToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  span {
    flex: 1;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border-color: rgba(136, 83, 251, 0.4);
  }
`;

const AdvancedActionsToggle = styled(LanguageToggle)``;

const LanguageDropdown = styled(motion.div)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  margin-top: 8px;
  overflow: hidden;
`;

const AdvancedActionsDropdown = styled(LanguageDropdown)``;

const LanguageOption = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.05)
    );
  }
  &.active {
    color: #8853fb;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.1)
    );
  }
`;

const TextButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.text};
  padding: 0;
  width: 100%;
  text-align: left;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  &.delete-button {
    color: ${({ theme }) => theme.colors.error};
    &:hover {
      color: ${({ theme }) => theme.colors.errorHover};
    }
  }
`;

const ActionDescription = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;
  margin: 8px 0 0 32px;
  opacity: 0.7;
`;

const SecondaryButtonClass = styled.button`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(20px);
  box-shadow:
    0 0 0 1px rgba(136, 83, 251, 0.2),
    0 8px 20px rgba(136, 83, 251, 0.15);
  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow:
      0 12px 32px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const SaveButtonClass = styled.button`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.25),
    rgba(96, 57, 176, 0.25)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(20px);
  box-shadow:
    0 0 0 1px rgba(136, 83, 251, 0.2),
    0 8px 20px rgba(136, 83, 251, 0.15);
  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.35),
      rgba(96, 57, 176, 0.35)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow:
      0 12px 32px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const AccountSettings = () => {
  const {
    user,
    loading,
    error,
    updateUserData,
    changePassword,
    validateCurrentPassword,
  } = useContext(AuthContext);
  const [userData, setUserData] = useState(user || {});
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
  const [toast, setToast] = useState(null);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState("");
  const { t, i18n } = useTranslation();
  const [availableLanguages] = useState(["en", "ptbr"]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (activeDropdown) {
      timer = setTimeout(() => {
        setActiveDropdown(null);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [activeDropdown]);

  const debouncedValidatePassword = useCallback(
    debounce(async (value) => {
      const result = await validateCurrentPassword(value);
      setIsCurrentPasswordValid(result.success);
      if (!result.success) {
        setPasswordError(result.error);
      } else {
        setPasswordError("");
      }
    }, 1000),
    [validateCurrentPassword]
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "email") return;
    setUserData((prevState) => {
      const newState = { ...prevState };
      if (name.includes(".")) {
        const [category, setting] = name.split(".");
        newState[category] = {
          ...newState[category],
          [setting]: type === "checkbox" ? checked : value,
        };
      } else {
        newState[name] = type === "checkbox" ? checked : value;
      }
      return newState;
    });
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "currentPassword") {
      if (value === "") {
        setShowPasswordForm(false);
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setIsCurrentPasswordValid(false);
        setShowPasswordRequirements(false);
        setShowCancelButton(false);
        setPasswordError("");
      } else {
        debouncedValidatePassword(value);
      }
    }
    if (name === "newPassword") {
      setShowCancelButton(value.length > 0);
      if (value === passwordData.currentPassword) {
        setNewPasswordError("Avoid reusing your last password.");
      } else {
        setNewPasswordError("");
      }
    }
    if (name === "newPassword" || name === "confirmPassword") {
      setShowPasswordRequirements(true);
      checkPasswordRequirements(
        name === "newPassword" ? value : passwordData.newPassword
      );
      checkPasswordsMatch(
        name === "newPassword" ? value : passwordData.newPassword,
        name === "confirmPassword" ? value : passwordData.confirmPassword
      );
    }
  };

  const checkPasswordRequirements = (password) => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*]/.test(password),
    });
  };

  const checkPasswordsMatch = (newPassword, confirmPassword) => {
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const isPasswordValid = () => {
    return (
      passwordRequirements.length &&
      passwordRequirements.uppercase &&
      passwordRequirements.lowercase &&
      passwordRequirements.number &&
      passwordRequirements.special &&
      passwordsMatch &&
      isCurrentPasswordValid
    );
  };

  const handleSaveChanges = async () => {
    const result = await updateUserData(userData);
    if (result.success) {
      setToast({ message: "Settings updated successfully!", type: "success" });
    } else {
      setToast({
        message: `Failed to update settings: ${result.error}`,
        type: "error",
      });
    }
  };

  const handlePasswordChange = async () => {
    if (isPasswordValid()) {
      const result = await changePassword(passwordData);
      if (result.success) {
        setToast({
          message: "Password changed successfully!",
          type: "success",
        });
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setShowPasswordForm(false);
        setIsCurrentPasswordValid(false);
        setShowPasswordRequirements(false);
        setShowCancelButton(false);
        setTimeout(async () => {
          await validateCurrentPassword(passwordData.newPassword);
        }, 2000);
      } else {
        setToast({ message: result.error, type: "error" });
      }
    } else {
      setToast({
        message: "Please ensure all password requirements are met.",
        type: "error",
      });
    }
  };

  const handleOpenModal = (modalType) => {
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handleLogout = () => {
    setActiveModal("logout");
  };

  const handleDeactivate = () => {
    setActiveModal("deactivate");
  };

  const handleDelete = () => {
    setActiveModal("delete");
  };

  const confirmLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
        method: "POST",
        credentials: "include",
      });
      
      if (response.ok) {
        // Close the modal first
        handleCloseModal();
        // Navigate to landing page
        navigate("/", { replace: true });
      } else {
        console.error("Logout failed");
      }
    } catch (err) {
      console.error("Sign out error:", err);
    }
  };

  const confirmDeactivate = async () => {
    setActiveModal(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/deactivate`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setToast({
          message: "Account deactivated. You will be logged out.",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to deactivate account");
      }
    } catch (error) {
      setToast({
        message: `Error deactivating account: ${error.message}`,
        type: "error",
      });
    }
  };

  const confirmDelete = async () => {
    setActiveModal(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/delete`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setToast({
          message:
            "Account deletion process initiated. Your account will be deleted within 30 days. You can reactivate by logging back in during this period.",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to initiate account deletion");
      }
    } catch (error) {
      setToast({
        message: `Error initiating account deletion: ${error.message}`,
        type: "error",
      });
    }
  };

  const handleLanguageChange = async (language) => {
    try {
      const updatedUserData = { ...userData, preferredLanguage: language };
      const result = await updateUserData(updatedUserData);
      if (result.success) {
        setUserData(updatedUserData);
        i18n.changeLanguage(language);
        setToast({ message: t("languageUpdateSuccess"), type: "success" });
      } else {
        setToast({ message: t("languageUpdateError"), type: "error" });
      }
    } catch (error) {
      setToast({ message: t("languageUpdateError"), type: "error" });
    }
  };

  const handleDropdownToggle = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  if (loading) return <LoadingOverlay />;
  if (error) return <div>Error: {error}</div>;

  const sectionVariants = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  };

  return (
    <PageContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
    >
      <ContentWrapper
        initial={{ opacity: 0, y: 20, scale: 0.98 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        transition={{
          duration: 0.5,
          ease: [0.16, 1, 0.3, 1],
          delay: 0.1,
        }}
      >
        <SettingsContent>
          <SettingsGrid>
            <motion.section
              className="settings-section"
              variants={sectionVariants}
            >
              <h2>{t("personalInformation")}</h2>
              <FormGroup>
                <InputLabelGroup>
                  <label htmlFor="name">{t("name")}</label>
                </InputLabelGroup>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={userData.name || ""}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <InputLabelGroup>
                  <label htmlFor="email">{t("email")}</label>
                </InputLabelGroup>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={userData.email || ""}
                  onChange={handleInputChange}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <InputLabelGroup>
                  <label htmlFor="location">{t("location")}</label>
                </InputLabelGroup>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={userData.location || ""}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <InputLabelGroup>
                  <label htmlFor="bio">{t("bio")}</label>
                </InputLabelGroup>
                <textarea
                  id="bio"
                  name="bio"
                  value={userData.bio || ""}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </motion.section>
            <motion.section
              className="settings-section"
              variants={sectionVariants}
            >
              <h2>{t("security")}</h2>
              {!showPasswordForm ? (
                <SecondaryButton
                  onClick={() => setShowPasswordForm(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {t("changePassword")}
                </SecondaryButton>
              ) : (
                <div>
                  <FormGroup>
                    <PasswordLabelGroup>
                      <label htmlFor="currentPassword">
                        {t("currentPassword")}
                      </label>
                    </PasswordLabelGroup>
                    <PasswordInputWrapper>
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        name="currentPassword"
                        value={passwordData.currentPassword}
                        onChange={handlePasswordInputChange}
                        required
                        className={passwordError ? "error" : ""}
                      />
                      <TogglePasswordButton
                        type="button"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      >
                        {showCurrentPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </TogglePasswordButton>
                      <AnimatePresence>
                        {passwordError && (
                          <ValidationMessage
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <AlertTriangle size={14} />
                            {passwordError}
                          </ValidationMessage>
                        )}
                      </AnimatePresence>
                    </PasswordInputWrapper>
                  </FormGroup>
                  {isCurrentPasswordValid && (
                    <>
                      <FormGroup>
                        <PasswordLabelGroup>
                          <label htmlFor="newPassword">
                            {t("newPassword")}
                          </label>
                        </PasswordLabelGroup>
                        <PasswordInputWrapper>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            id="newPassword"
                            name="newPassword"
                            value={passwordData.newPassword}
                            onChange={handlePasswordInputChange}
                            required
                            className={newPasswordError ? "error" : ""}
                          />
                          <TogglePasswordButton
                            type="button"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          >
                            {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                          </TogglePasswordButton>
                          <AnimatePresence>
                            {newPasswordError && (
                              <ValidationMessage
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.2 }}
                              >
                                <AlertTriangle size={14} />
                                {newPasswordError}
                              </ValidationMessage>
                            )}
                          </AnimatePresence>
                        </PasswordInputWrapper>
                      </FormGroup>
                      <FormGroup>
                        <PasswordLabelGroup>
                          <label htmlFor="confirmPassword">
                            {t("confirmNewPassword")}
                          </label>
                        </PasswordLabelGroup>
                        <PasswordInputWrapper>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            name="confirmPassword"
                            value={passwordData.confirmPassword}
                            onChange={handlePasswordInputChange}
                            required
                          />
                          <TogglePasswordButton
                            type="button"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                          </TogglePasswordButton>
                        </PasswordInputWrapper>
                      </FormGroup>
                    </>
                  )}
                  {showPasswordRequirements && (
                    <PasswordRequirements className="password-requirements">
                      <p>{t("passwordMust")}:</p>
                      <ul>
                        <li className={passwordRequirements.length ? "met" : ""}>
                          {passwordRequirements.length ? <Check size={14} /> : <X size={14} />}
                          {t("beAtLeast8CharactersLong")}
                        </li>
                        <li className={passwordRequirements.uppercase ? "met" : ""}>
                          {passwordRequirements.uppercase ? <Check size={14} /> : <X size={14} />}
                          {t("containAtLeastOneUppercaseLetter")}
                        </li>
                        <li className={passwordRequirements.lowercase ? "met" : ""}>
                          {passwordRequirements.lowercase ? <Check size={14} /> : <X size={14} />}
                          {t("containAtLeastOneLowercaseLetter")}
                        </li>
                        <li className={passwordRequirements.number ? "met" : ""}>
                          {passwordRequirements.number ? <Check size={14} /> : <X size={14} />}
                          {t("containAtLeastOneNumber")}
                        </li>
                        <li className={passwordRequirements.special ? "met" : ""}>
                          {passwordRequirements.special ? <Check size={14} /> : <X size={14} />}
                          {t("containAtLeastOneSpecialCharacter")}
                        </li>
                        <li className={passwordsMatch ? "met" : ""}>
                          {passwordsMatch ? <Check size={14} /> : <X size={14} />}
                          {t("passwordsMustMatch")}
                        </li>
                      </ul>
                    </PasswordRequirements>
                  )}
                  <ButtonContainer className="button-container">
                    {showCancelButton && (
                      <CancelButton
                        type="button"
                        onClick={() => {
                          setShowPasswordForm(false);
                          setPasswordData({
                            currentPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                          });
                          setShowCancelButton(false);
                        }}
                      >
                        {t("cancel")}
                      </CancelButton>
                    )}
                    {isPasswordValid() && (
                      <SaveButtonClass
                        type="button"
                        onClick={handlePasswordChange}
                        className="save-button"
                      >
                        {t("changePassword")}
                      </SaveButtonClass>
                    )}
                  </ButtonContainer>
                </div>
              )}
            </motion.section>
            <motion.section
              className="settings-section"
              variants={sectionVariants}
            >
              <h2>{t("accountActions")}</h2>
              <AccountActions className="account-actions">
                <LanguagePreferences className="language-preferences">
                  <LanguageToggle
                    className="language-toggle"
                    onClick={() => handleDropdownToggle("language")}
                  >
                    <Globe size={20} />
                    <span>{t("languagePreferences")}</span>
                    {activeDropdown === "language" ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </LanguageToggle>
                  <AnimatePresence>
                    {activeDropdown === "language" && (
                      <LanguageDropdown
                        className="language-dropdown"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
                      >
                        {availableLanguages.map((lang) => (
                          <LanguageOption
                            key={lang}
                            className={`language-option ${
                              userData.preferredLanguage === lang
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleLanguageChange(lang)}
                          >
                            {userData.preferredLanguage === lang && <Check size={16} />}
                            {t(`language.${lang}`)}
                          </LanguageOption>
                        ))}
                      </LanguageDropdown>
                    )}
                  </AnimatePresence>
                </LanguagePreferences>
                <AdvancedActions className="advanced-actions">
                  <AdvancedActionsToggle
                    className="advanced-actions-toggle"
                    onClick={() => handleDropdownToggle("advanced")}
                  >
                    <SettingsIcon size={20} />
                    <span>{t("advancedActions")}</span>
                    {activeDropdown === "advanced" ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </AdvancedActionsToggle>
                  <AnimatePresence>
                    {activeDropdown === "advanced" && (
                      <AdvancedActionsDropdown
                        className="advanced-actions-dropdown"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
                      >
                        <AccountActionItem
                          className="account-action-item"
                          onClick={handleDeactivate}
                        >
                          <TextButton className="text-button deactivate-button">
                            <UserMinus size={20} />
                            <span>{t("deactivateAccount")}</span>
                          </TextButton>
                          <ActionDescription className="action-description">
                            {t("deactivateAccountDescription")}
                          </ActionDescription>
                        </AccountActionItem>
                        <AccountActionItem
                          className="account-action-item"
                          onClick={handleDelete}
                        >
                          <TextButton className="text-button delete-button">
                            <Trash2 size={20} />
                            <span>{t("deleteAccount")}</span>
                          </TextButton>
                          <ActionDescription className="action-description">
                            {t("deleteAccountDescription")}
                          </ActionDescription>
                        </AccountActionItem>
                      </AdvancedActionsDropdown>
                    )}
                  </AnimatePresence>
                </AdvancedActions>
              </AccountActions>
            </motion.section>
          </SettingsGrid>
        </SettingsContent>
        <BottomActionsContainer
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.3,
            ease: [0.16, 1, 0.3, 1],
          }}
        >
          <LogoutButton onClick={handleLogout}>
            <ExitToAppIcon size={20} />
            {t("logout")}
          </LogoutButton>
          <LegalLinksContainer>
            <LegalLink onClick={() => handleOpenModal("privacyPolicy")}>
              <Link size={14} />
              {t("privacyPolicy")}
            </LegalLink>
            <LegalLink onClick={() => handleOpenModal("termsOfService")}>
              <Link size={14} />
              {t("termsOfService")}
            </LegalLink>
          </LegalLinksContainer>
          <SaveButton onClick={handleSaveChanges} whileTap={{ scale: 0.98 }}>
            <Check size={20} />
            {t("saveChanges")}
          </SaveButton>
        </BottomActionsContainer>
      </ContentWrapper>
      <ConfirmationModal
        isOpen={activeModal === "logout"}
        message={t("areYouSureYouWantToLogOut")}
        onConfirm={confirmLogout}
        onClose={handleCloseModal}
        confirmLabel={t("logout")}
      />
      <ConfirmationModal
        isOpen={activeModal === "deactivate"}
        message={t("areYouSureYouWantToDeactivateYourAccount")}
        onConfirm={confirmDeactivate}
        onClose={handleCloseModal}
        confirmLabel={t("deactivate")}
      />
      <ConfirmationModal
        isOpen={activeModal === "delete"}
        message={t("areYouSureYouWantToDeleteYourAccount")}
        onConfirm={confirmDelete}
        onClose={handleCloseModal}
      />
      <PolicyModal
        open={
          activeModal === "privacyPolicy" || activeModal === "termsOfService"
        }
        onClose={handleCloseModal}
        title={
          activeModal === "privacyPolicy"
            ? t("privacyPolicy")
            : t("termsOfService")
        }
        content={
          activeModal === "privacyPolicy"
            ? privacyPolicyContent
            : termsOfServiceContent
        }
      />
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </PageContainer>
  );
};

export default AccountSettings;