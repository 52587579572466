class SwarmManager {
  constructor() {
    this.instances = new Map();
    this.configs = new Map();
    this.isTransitioning = false;
    this.animationLoop = null;
    this.performanceMonitoring = {
      enabled: false,
      frameCount: 0,
      lastTime: 0,
      history: []
    };
    this.originalConfigs = new Map();
    this.maxParticles = {
      normal: {
        intro: 250,      // Large section
        solution: 180,   // Medium section
        journey: 120     // Small section
      },
      frenetic: {
        intro: 300,      // Large section
        solution: 220,   // Medium section
        journey: 150     // Small section
      },
      grid: {
        intro: 800,
        solution: 600,
        journey: 400
      }
    };
  }

  getConfig(mode, isSmallContainer) {
    const key = `${mode}-${isSmallContainer}`;
    
    if (this.configs.has(key)) {
      return this.configs.get(key);
    }

    const config = this.createConfig(mode, isSmallContainer);
    this.configs.set(key, config);
    this.originalConfigs.set(key, JSON.parse(JSON.stringify(config)));
    return config;
  }

  createConfig(mode, isSmallContainer) {
    // Helper function to determine section type from container ID
    const getSectionType = (containerId) => {
      if (containerId?.includes('intro')) return 'intro';
      if (containerId?.includes('solution')) return 'solution';
      if (containerId?.includes('journey')) return 'journey';
      return 'intro'; // default to intro size if unknown
    };

    const sectionType = getSectionType(this.currentContainerId);
    
    const baseConfig = {
      particles: {
        number: {
          value: mode === 'grid' ? 
            this.maxParticles.grid[sectionType] :
            mode === 'frenetic' ? 
              this.maxParticles.frenetic[sectionType] :
              this.maxParticles.normal[sectionType],
          density: {
            enable: mode !== 'grid',
            value_area: mode === 'grid' ? (isSmallContainer ? 800 : 1600) :
                       mode === 'frenetic' ? 800 :
                       (isSmallContainer ? 800 : 1000),
          }
        },
        color: {
          value: mode === 'grid' ? "#8853fb" :
                mode === 'frenetic' ? ["#ffffff", "#8853fb", "#6039b0", "#4c2d8c", "#3a2268"] :
                "#ffffff"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000"
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: mode === 'grid' ? 0.3 :
                mode === 'frenetic' ? 0.5 :
                0.6,
          random: mode === 'frenetic',
          anim: {
            enable: mode !== 'grid',
            speed: mode === 'frenetic' ? 3 : 1,
            opacity_min: mode === 'frenetic' ? 0.1 : 0.4,
            sync: false
          }
        },
        size: {
          value: mode === 'grid' ? 1.5 :
                (isSmallContainer ? 2 : 3),
          random: mode !== 'grid',
          anim: {
            enable: mode !== 'grid',
            speed: mode === 'frenetic' ? 1 : 2,
            size_min: mode === 'frenetic' ? 0.5 :
                     (isSmallContainer ? 0.5 : 1),
            sync: false
          }
        },
        line_linked: {
          enable: mode === 'grid' ? false : true,
          distance: mode === 'grid' ? 150 :
                   mode === 'frenetic' ? 40 :
                   (isSmallContainer ? 120 : 180),
          color: mode === 'grid' ? "rgba(136, 83, 251, 0.2)" : "#8853fb",
          opacity: mode === 'grid' ? 0.2 :
                  mode === 'frenetic' ? 1 :
                  0.5,
          width: mode === 'grid' ? 1 :
                (isSmallContainer ? 0.8 : 1)
        },
        move: {
          enable: mode !== 'grid',
          speed: mode === 'grid' ? 0 :
                mode === 'frenetic' ? (isSmallContainer ? 5 : 10) :
                (isSmallContainer ? 3 : 4),
          direction: "none",
          random: mode === 'frenetic',
          straight: false,
          out_mode: mode === 'grid' ? "out" : "bounce",
          bounce: mode !== 'grid',
          attract: {
            enable: mode === 'normal',
            rotateX: 1000,
            rotateY: 1000
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: mode === 'grid' ? "grab" :
                  mode === 'frenetic' ? "bubble" :
                  "grab"
          },
          onclick: {
            enable: false
          },
          resize: true
        },
        modes: {
          grab: {
            distance: mode === 'grid' ? 150 :
                     (isSmallContainer ? 140 : 200),
            line_linked: {
              opacity: mode === 'grid' ? 0.3 : 1
            }
          },
          bubble: {
            distance: 200,
            size: 4,
            duration: 0.4,
            opacity: 0.8,
            speed: 3
          }
        }
      },
      retina_detect: true,
      fps_limit: 60
    };

    return baseConfig;
  }

  async initParticles(containerId, mode, isSmallContainer) {
    if (!window.particlesJS || this.isTransitioning) return null;
    
    const container = document.getElementById(containerId);
    if (!container) return null;

    // If we already have an instance, destroy it first
    if (this.instances.has(containerId)) {
      this.destroy(containerId);
    }

    // Create new instance
    const config = this.getConfig(mode, isSmallContainer);
    
    // Ensure the container is empty and properly sized
    container.innerHTML = '';
    container.style.width = '100%';
    container.style.height = '100%';

    try {
      // Wait for next frame to ensure container is ready
      await new Promise(resolve => requestAnimationFrame(resolve));
      
      // For grid mode, we need to initialize differently
      if (mode === 'grid') {
        const pJS = {
          canvas: {
            el: document.createElement('canvas'),
            w: container.offsetWidth,
            h: container.offsetHeight
          },
          particles: {
            array: [],
            color: { value: "#8853fb" },
            shape: { type: "circle" },
            opacity: { value: 0.3 },
            size: { value: 1.5 },
            line_linked: { enable: false },
            move: { enable: false }
          },
          retina_detect: true,
          fps_limit: 60
        };

        container.appendChild(pJS.canvas.el);
        pJS.canvas.el.width = pJS.canvas.w;
        pJS.canvas.el.height = pJS.canvas.h;
        pJS.canvas.ctx = pJS.canvas.el.getContext('2d');

        // Create particles but don't render yet
        const particles = this.createGridParticles(containerId);
        
        // Only set the particles array once all calculations are done
        pJS.particles.array = particles;

        const instance = { pJS };
        this.instances.set(containerId, {
          instance,
          originalConfig: JSON.parse(JSON.stringify(config))
        });

        this.ensureAnimationLoop();
        return instance;
      }
      
      // For non-grid modes, use standard initialization
      window.particlesJS(containerId, config);
      
      if (window.pJSDom && window.pJSDom.length > 0) {
        const instance = window.pJSDom[window.pJSDom.length - 1];
        
        if (instance && instance.pJS) {
          this.instances.set(containerId, {
            instance,
            originalConfig: JSON.parse(JSON.stringify(config))
          });
          
          this.ensureAnimationLoop();
          return instance;
        }
      }
    } catch (error) {
      console.error('Error initializing particles:', error);
      return null;
    }
    
    return null;
  }

  async updateConfiguration(containerId, mode, isSmallContainer) {
    const instance = this.instances.get(containerId);
    if (!instance || !instance.pJS) return;

    const config = this.getConfig(mode, isSmallContainer);
    const pJS = instance.pJS;

    // Pause the animation loop
    this.stopAnimationLoop();

    try {
      // Update configuration
      pJS.particles.array = [];
      Object.assign(pJS.particles, config.particles);
      Object.assign(pJS.interactivity, config.interactivity);

      // Recreate particles
      pJS.fn.particlesEmpty();
      pJS.fn.particlesCreate();
      pJS.fn.particlesDraw();
      
      // Restart animation loop
      this.ensureAnimationLoop();
    } catch (error) {
      console.error('Error updating particles configuration:', error);
      // Attempt recovery by reinitializing
      this.initParticles(containerId, mode, isSmallContainer);
    }
  }

  destroy(containerId) {
    const instance = this.instances.get(containerId);
    if (instance) {
      try {
        if (instance.pJS) {
          // Clear particles array
          if (instance.pJS.particles) {
            instance.pJS.particles.array = [];
          }
          // Remove canvas and event listeners
          if (instance.pJS.canvas && instance.pJS.canvas.el) {
            const canvas = instance.pJS.canvas.el;
            canvas.removeEventListener('mousemove', canvas._mouseHandler);
            canvas.remove();
          }
          // Clear any remaining references
          instance.pJS = null;
        }
      } catch (error) {
        console.error('Error during cleanup:', error);
      }
      
      this.instances.delete(containerId);

      // Clear from configs map as well
      this.configs.delete(`normal-true`);
      this.configs.delete(`normal-false`);
      this.configs.delete(`frenetic-true`);
      this.configs.delete(`frenetic-false`);
      this.configs.delete(`grid-true`);
      this.configs.delete(`grid-false`);
    }

    if (this.instances.size === 0) {
      this.stopAnimationLoop();
      // Clear all caches
      this.configs.clear();
      this.originalConfigs.clear();
    }
  }

  destroyAll() {
    for (const containerId of this.instances.keys()) {
      this.destroy(containerId);
    }
    this.stopAnimationLoop();
  }

  ensureAnimationLoop() {
    if (!this.animationLoop) {
      this.startAnimationLoop();
    }
  }

  startAnimationLoop() {
    const animate = () => {
      let hasActiveInstances = false;

      this.instances.forEach(({instance}) => {
        if (instance?.pJS?.particles) {
          try {
            const pJS = instance.pJS;
            const ctx = pJS.canvas?.ctx;
            
            if (!ctx) return;
            
            // Clear canvas
            ctx.clearRect(0, 0, pJS.canvas.w, pJS.canvas.h);
            
            // Check if this is a grid mode instance
            const isGridMode = pJS.particles.move.enable === false;
            
            if (isGridMode) {
              // Update and draw grid particles
              if (Array.isArray(pJS.particles.array) && pJS.particles.array.length > 0) {
                this.updateGridParticles(instance);
                
                // Draw particles with glow effect
                pJS.particles.array.forEach(particle => {
                  if (particle?.radius !== undefined && 
                      Number.isFinite(particle.x) && 
                      Number.isFinite(particle.y) && 
                      Number.isFinite(particle.radius) && 
                      Number.isFinite(particle.opacity)) {
                    try {
                      // Draw glow
                      const glowRadius = Math.max(1, particle.radius * 2);
                      const gradient = ctx.createRadialGradient(
                        particle.x, particle.y, 0,
                        particle.x, particle.y, glowRadius
                      );
                      gradient.addColorStop(0, `rgba(136, 83, 251, ${particle.opacity * 0.5})`);
                      gradient.addColorStop(1, 'rgba(136, 83, 251, 0)');
                      
                      ctx.beginPath();
                      ctx.arc(particle.x, particle.y, glowRadius, 0, Math.PI * 2, false);
                      ctx.fillStyle = gradient;
                      ctx.fill();
                      
                      // Draw particle
                      ctx.beginPath();
                      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2, false);
                      ctx.fillStyle = `rgba(136, 83, 251, ${particle.opacity})`;
                      ctx.fill();
                    } catch (drawError) {
                      console.warn('Error drawing particle:', drawError);
                    }
                  }
                });
              }
            } else {
              // For normal and frenetic modes, use particles.js built-in drawing
              if (pJS.fn) {
                pJS.fn.particlesUpdate();
                pJS.fn.particlesDraw();
              }
            }
            
            hasActiveInstances = true;
          } catch (error) {
            console.error('Error in animation loop:', error);
            try {
              if (instance.pJS?.canvas?.el?.id) {
                this.instances.delete(instance.pJS.canvas.el.id);
              }
            } catch (cleanupError) {
              console.warn('Error cleaning up instance:', cleanupError);
            }
          }
        }
      });

      if (this.performanceMonitoring.enabled) {
        try {
          this.checkPerformance();
        } catch (error) {
          console.warn('Error in performance monitoring:', error);
          this.stopPerformanceMonitoring();
        }
      }

      if (hasActiveInstances) {
        this.animationLoop = requestAnimationFrame(animate);
      } else {
        this.stopAnimationLoop();
      }
    };

    this.animationLoop = requestAnimationFrame(animate);
  }

  stopAnimationLoop() {
    if (this.animationLoop) {
      cancelAnimationFrame(this.animationLoop);
      this.animationLoop = null;
    }
  }

  pause(containerId) {
    const instance = this.instances.get(containerId);
    if (instance && instance.pJS) {
      instance.pJS.particles.move.enable = false;
    }
  }

  resume(containerId) {
    const instance = this.instances.get(containerId);
    if (instance && instance.pJS) {
      instance.pJS.particles.move.enable = true;
    }
  }

  startPerformanceMonitoring() {
    this.performanceMonitoring = {
      enabled: true,
      frameCount: 0,
      lastTime: performance.now(),
      history: []
    };
  }

  stopPerformanceMonitoring() {
    this.performanceMonitoring = {
      enabled: false,
      frameCount: 0,
      lastTime: 0,
      history: []
    };
  }

  checkPerformance() {
    if (!this.performanceMonitoring.enabled) return;

    const now = performance.now();
    this.performanceMonitoring.frameCount++;

    if (now - this.performanceMonitoring.lastTime >= 1000) {
      const fps = this.performanceMonitoring.frameCount;
      this.performanceMonitoring.history.push(fps);
      
      if (this.performanceMonitoring.history.length > 5) {
        this.performanceMonitoring.history.shift();
      }

      const avgFps = this.performanceMonitoring.history.reduce((a, b) => a + b, 0) / 
                    this.performanceMonitoring.history.length;

      if (avgFps < 20) {
        this.instances.forEach(({instance, originalConfig}) => {
          if (instance?.pJS?.particles?.number && originalConfig?.particles?.number?.value) {
            const pJS = instance.pJS;
            const currentValue = pJS.particles.number.value;
            const mode = pJS.particles.move.enable === false ? 'grid' : 
                        (pJS.particles.move.speed >= 5 ? 'frenetic' : 'normal');
            
            // Don't reduce below 50% of original and respect max limits
            const minParticles = Math.floor(originalConfig.particles.number.value * 0.5);
            const maxParticles = this.maxParticles[mode] || this.maxParticles.normal;
            const newValue = Math.min(
              maxParticles,
              Math.max(minParticles, Math.floor(currentValue * 0.9))
            );
            
            if (newValue !== currentValue && pJS.fn) {
              try {
                pJS.particles.number.value = newValue;
                pJS.fn.particlesEmpty();
                pJS.fn.particlesCreate();
                pJS.fn.particlesDraw();
              } catch (error) {
                console.warn('Error adjusting particle count:', error);
                this.stopPerformanceMonitoring();
              }
            }
          }
        });
      }

      this.performanceMonitoring.frameCount = 0;
      this.performanceMonitoring.lastTime = now;
    }
  }

  createGridParticles(containerId, cols = 49, rows = 25) {
    const container = document.getElementById(containerId);
    if (!container) return [];

    const width = container.offsetWidth;
    const height = container.offsetHeight;
    
    // Define reference sizes for different sections
    const sectionSizes = {
      introSection: 600,    // Large orb (reference size)
      solutionSection: 400, // Medium orb
      journeySection: 300   // Small orb
    };

    // Determine which section we're dealing with based on container ID
    let referenceSize = sectionSizes.introSection; // default to intro size
    if (containerId.includes('solution')) {
      referenceSize = sectionSizes.solutionSection;
    } else if (containerId.includes('journey')) {
      referenceSize = sectionSizes.journeySection;
    }

    // Calculate base grid dimensions based on section type
    let baseCols, baseRows;
    if (containerId.includes('intro')) {
      baseCols = 35;
      baseRows = 20;
    } else if (containerId.includes('solution')) {
      baseCols = 30;
      baseRows = 18;
    } else if (containerId.includes('journey')) {
      baseCols = 25;
      baseRows = 15;
    } else {
      baseCols = 35;
      baseRows = 20;
    }

    // Calculate scaling factor based on the actual orb size
    const orbDimension = Math.min(width, height);
    const scaleFactor = Math.min(1.2, Math.max(0.5, orbDimension / referenceSize));
    
    // Apply scaling with section-specific constraints
    const adjustedCols = Math.max(
      containerId.includes('journey') ? 12 : 15,
      Math.min(
        containerId.includes('intro') ? 40 : 35,
        Math.floor(baseCols * scaleFactor)
      )
    );
    
    const adjustedRows = Math.max(
      containerId.includes('journey') ? 10 : 12,
      Math.min(
        containerId.includes('intro') ? 30 : 25,
        Math.floor(baseRows * scaleFactor)
      )
    );
    
    // Set maximum particles based on section
    const maxParticles = containerId.includes('intro') ? 800 :
                        containerId.includes('solution') ? 600 :
                        containerId.includes('journey') ? 400 : 600;
    
    const totalCells = adjustedCols * adjustedRows;
    let finalCols = adjustedCols;
    let finalRows = adjustedRows;
    
    if (totalCells > maxParticles) {
      const ratio = Math.sqrt(maxParticles / totalCells);
      finalCols = Math.max(
        containerId.includes('journey') ? 12 : 15,
        Math.floor(adjustedCols * ratio)
      );
      finalRows = Math.max(
        containerId.includes('journey') ? 10 : 12,
        Math.floor(adjustedRows * ratio)
      );
    }
    
    const cellWidth = width / finalCols;
    const cellHeight = height / finalRows;

    const particles = [];
    const baseRadius = containerId.includes('journey') ? 1.2 :
                      containerId.includes('solution') ? 1.5 : 1.5;
    const baseOpacity = 0.3;
    
    // Create particles with final calculations
    for (let i = 0; i < finalCols; i++) {
      for (let j = 0; j < finalRows; j++) {
        particles.push({
          x: (i + 0.5) * cellWidth,
          y: (j + 0.5) * cellHeight,
          baseX: (i + 0.5) * cellWidth,
          baseY: (j + 0.5) * cellHeight,
          radius: baseRadius,
          baseRadius: baseRadius,
          color: `rgba(136, 83, 251, ${baseOpacity})`,
          angle: Math.random() * Math.PI * 2,
          velocity: 0.2 + Math.random() * 0.3,
          amplitude: 2 + Math.random() * 3,
          phase: Math.random() * Math.PI * 2,
          opacity: baseOpacity
        });
      }
    }

    return particles;
  }

  updateGridParticles(instance) {
    if (!instance?.pJS) return;

    const pJS = instance.pJS;
    const time = performance.now() * 0.001; // Convert to seconds
    const bounds = {
      width: pJS.canvas.w,
      height: pJS.canvas.h
    };
    
    pJS.particles.array.forEach(particle => {
      try {
        // Autonomous movement
        particle.angle = (particle.angle + particle.velocity * 0.02) % (Math.PI * 2);
        const dx = Math.cos(particle.angle + time) * particle.amplitude;
        const dy = Math.sin(particle.angle * 1.5 + time * 0.7) * particle.amplitude;
        
        // Calculate new position
        particle.x = particle.baseX + dx;
        particle.y = particle.baseY + dy;
        
        // Clamp positions to canvas bounds
        particle.x = Math.max(0, Math.min(bounds.width, particle.x));
        particle.y = Math.max(0, Math.min(bounds.height, particle.y));

        // Keep radius and opacity constant
        particle.radius = particle.baseRadius;
        particle.opacity = 0.3;

      } catch (error) {
        // Reset particle to safe values if any calculation fails
        particle.x = particle.baseX;
        particle.y = particle.baseY;
        particle.radius = particle.baseRadius;
        particle.opacity = 0.3;
      }
    });
  }
}

export default new SwarmManager(); 